import api from '../library/axios';
import config from '../config';

function checkActiveWindow({ workspace_id, conversation_id }) {
  return api({
    baseURL: config.api.chatUrl,
    method: 'get',
    url: `/api/conversations/${workspace_id}/${conversation_id}/check-active-window`,
  });
}

function preUploadMediaReply(workspace_id, conversation_id, data, uploadHandler) {
  return api({
    method: 'post',
    baseURL: config.api.chatUrl,
    url: `/api/conversations/${workspace_id}/${conversation_id}/upload-media-reply`,
    data,
    uploadHandler,
  });
}

function pingCall({ call_id, total_duration }) {
  return api({
    baseURL: config.api.chatUrl,
    method: 'post',
    url: '/api/conversations/ping-call',
    data: {
      call_id,
      total_duration,
    },
  });
}

function uploadAttachment(workspace_id, conversation_id, payload) {
  return api({
    baseURL: config.api.chatUrl,
    method: 'post',
    url: `/api/conversations/${workspace_id}/${conversation_id}/upload-media-reply`,
    data: payload.data,
    uploadHandler: payload.onUploadProgress,
  });
}

function getTranscriptText({ workspace_id, conversation_id, msg_id }) {
  return api({
    baseURL: config.api.chatUrl,
    url: `/api/conversations/${workspace_id}/${conversation_id}/${msg_id}/transcript`,
  });
}

export default {
  getList({
    workspace_id,
    page,
    search,
    search_by,
    status,
    assigned_to,
    label_id,
    sort_by = '',
    sort_type = '',
  }) {
    return api({
      method: 'get',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}`,
      params: {
        page,
        keyword: search,
        search_by,
        status,
        assigned_to,
        label_id,
        sort_by,
        sort_type,
      },
    });
  },
  getListDetail(workspace_id, conversation_id, page = 1, without_message = undefined) {
    return api({
      method: 'get',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}`,
      params: {
        page,
        without_message,
      },
    });
  },
  replyText(message, workspace_id, conversation_id) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/reply`,
      data: {
        type: 'text',
        message,
      },
    });
  },
  replyMessage({
    text,
    message,
    workspace_id,
    conversation_id,
    type,
    base64,
    caption,
    msg_id,
    reply_id,
    attachments,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/reply`,
      data: {
        type,
        text,
        message: ['text', 'private_note'].includes(type) ? message : base64,
        // caption: ['text', 'private_note'].includes(type) ? null : message,
        caption: caption || '',
        msg_id,
        reply_id,
        attachments,
      },
    });
  },
  endConversation({ workspace_id, conversation_id }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/resolve`,
      data: {
        send_closing_message: true,
      },
    });
  },
  pinChat({ workspace_id, conversation_id }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/pin`,
    });
  },
  getConversationHistory(workspace_id, contact_id, page = 1) {
    return api({
      method: 'get',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${contact_id}/history`,
      params: {
        page,
      },
    });
  },
  getConversationMedia(workspace_id, contact_id, page = 1) {
    return api({
      method: 'get',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${contact_id}/media`,
      params: {
        page,
      },
    });
  },
  reassignTicketAgent({
    workspace_id, conversation_id, recipient_type = 'individual', recipient,
  }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/reassign`,
      data: {
        recipient_type,
        recipient,
      },
    });
  },
  updateLabel({
    workspace_id,
    conversation_id,
    label_id,
    action,
  }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/labels`,
      data: {
        label_id,
        action,
      },
    });
  },
  takeOverUnhandled({
    workspace_id,
    conversation_id,
  }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/takeover-unhandled`,
    });
  },
  getReachTicketTemplates(workspace_id, conversation_id) {
    return api({
      method: 'get',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/reach-ticket/templates`,
    });
  },
  getStartTicketTemplates(workspace_id, channel_id) {
    return api({
      method: 'get',
      url: `/api/broadcast/templates/${workspace_id}/${channel_id}`,
    });
  },
  reachTicket({
    workspace_id,
    conversation_id,
    data,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/reach-ticket`,
      data,
    });
  },
  startTicket({
    workspace_id,
    channel_id,
    data,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${channel_id}/leads/start-ticket`,
      data,
    });
  },
  updateNotes({
    workspace_id,
    conversation_id,
    data,
  }) {
    return api({
      method: 'patch',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/notes`,
      data,
    });
  },
  summarize({
    workspace_id,
    conversation_id,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/summary`,
    });
  },
  callUser({
    workspace_id,
    conversation_id,
    agent_id,
  }) {
    return api({
      method: 'get',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/call/get`,
      data: {
        agent_id,
      },
    });
  },
  outgoingContactCall({
    workspace_id,
    contact_id,
    call_id,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/call/contact`,
      data: {
        contact_id,
        call_id,
      },
    });
  },
  markConversion({
    conversation_id,
    data,
    workspace_id,
  }) {
    return api({
      method: 'post',
      url: `/api/conversion/${workspace_id}/${conversation_id}`,
      data,
    });
  },
  outgoingConvoCall({
    workspace_id,
    conversation_id,
    call_id,
    phone_number,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/call/create`,
      data: {
        call_id,
        phone_number,
      },
    });
  },
  connectedCall({
    workspace_id,
    call_id,
    phone_number,
    call_type,
    calling_at,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/call/connect`,
      data: {
        call_id,
        phone_number,
        call_type,
        calling_at,
      },
    });
  },
  acceptedCall({
    workspace_id,
    conversation_id,
    msg_id,
    call_id,
    phone_number,
    call_type,
    accepted_at,
    id,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/call/accept`,
      data: {
        id,
        call_id,
        phone_number,
        msg_id,
        call_type,
        accepted_at,
      },
    });
  },
  creditCall({
    workspace_id,
    conversation_id,
    msg_id,
    call_id,
    id,
    total_duration,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/call/credit`,
      data: {
        msg_id,
        call_id,
        id,
        total_duration,
      },
    });
  },
  endCall({
    workspace_id,
    conversation_id,
    call_id,
    phone_number,
    msg_id,
    status,
    reason,
    time,
    call_type,
    id,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/call/resolve`,
      data: {
        call_id,
        phone_number,
        msg_id,
        status,
        reason,
        time,
        call_type,
        id,
      },
    });
  },
  callAction({
    workspace_id,
    conversation_id,
    action,
    call_id,
    sdp_answer,
    duration,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/call-action`,
      data: {
        action,
        call_id,
        sdp_answer,
        duration,
      },
    });
  },
  checkCallRequestStatus({
    workspace_id,
    conversation_id,
  }) {
    return api({
      baseURL: config.api.chatUrl,
      url: `/api/conversations/${workspace_id}/${conversation_id}/call-request`,
    });
  },
  initCallRequest({
    workspace_id,
    conversation_id,
  }) {
    return api({
      baseURL: config.api.chatUrl,
      method: 'post',
      url: `/api/conversations/${workspace_id}/${conversation_id}/call-request`,
    });
  },
  executeCall({
    workspace_id,
    conversation_id,
    session,
  }) {
    return api({
      baseURL: config.api.chatUrl,
      method: 'post',
      url: `/api/conversations/${workspace_id}/${conversation_id}/call-connect`,
      data: session,
    });
  },
  checkActiveWindow,
  preUploadMediaReply,
  pingCall,
  uploadAttachment,
  getTranscriptText,
};
